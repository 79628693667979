// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Changa:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-8f55d7a2]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-8f55d7a2]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-8f55d7a2]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-8f55d7a2]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
nav[data-v-8f55d7a2] {
  background: #f4f4f7;
  padding: 0.9rem calc(var(--size) + 2vw);
}
nav ol[data-v-8f55d7a2] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
nav ol li[data-v-8f55d7a2] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
nav ol li:first-child svg[data-v-8f55d7a2] {
  width: 1.24rem;
  height: 1.24rem;
  margin: 0 0.4rem 0 0;
}
nav ol li svg[data-v-8f55d7a2] {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin: 0 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./breadcrumb.vue","webpack://./src/js/components/breadcrumb.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;AClBF;ADmBC;AARD;IASE,eAAA;AChBA;AACF;ADiBC;AAXD;IAYE,eAAA;IACE,YAAA;ACdF;AACF;ADeC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACZF;AACF;ADuGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACpGD;AC9DA;EACE,mBFSK;EERL,uCAAA;ADgEF;AC/DE;EF4CD,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EE7CE,mBAAA;EACA,2BAAA;ADoEJ;ACnEI;EFwCH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EEzCI,mBAAA;EACA,2BAAA;ADwEN;ACtEQ;EACE,cAAA;EACA,eAAA;EACA,oBAAA;ADwEV;ACrEM;EACE,WAAA;EACA,YAAA;EACA,qBAAA;EACA,gBAAA;ADuER","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
