// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Changa:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7653fc8a]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7653fc8a]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-7653fc8a]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7653fc8a]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
header[data-v-7653fc8a] {
  width: calc(100% - calc(var(--size) + 2vw) * 2);
  position: fixed;
  top: 1rem;
  left: calc(var(--size) + 2vw);
  padding: 0.8rem;
  border-radius: 999rem;
  background: #fff;
  display: grid;
  grid-template-columns: auto auto;
  box-shadow: 0 0.4rem 2.4rem rgba(249, 221, 238, 0.24);
  z-index: 1000;
}
header[data-v-7653fc8a] > * {
  align-self: center;
}
header .logo[data-v-7653fc8a] {
  width: 9.6rem;
  justify-self: flex-start;
  margin: 0 0 0 1rem;
}
header .logo img[data-v-7653fc8a] {
  width: 100%;
}
header nav[data-v-7653fc8a] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
header nav ul[data-v-7653fc8a] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
header nav ul li[data-v-7653fc8a] {
  line-height: 1;
  margin: 0 0 0 2.4rem;
}
header nav ul li[data-v-7653fc8a]:first-child {
  margin-left: 0;
}
header nav ul li a[data-v-7653fc8a] {
  text-transform: uppercase;
  font-size: 1.04rem;
  color: #625555;
  fill: #625555;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0.4rem;
  z-index: 1;
}
header nav ul li a[data-v-7653fc8a]:hover {
  color: #f16bae;
  fill: #f16bae;
}
header nav ul li a svg[data-v-7653fc8a] {
  width: 7.2vmin;
  height: 7.2vmin;
  display: inline-block;
  margin: 0 0 0 0.8vmin;
}
header .contact[data-v-7653fc8a] {
  justify-self: flex-end;
  color: #fff;
  border: #f16bae 1px solid;
  font-size: 1.08rem;
  line-height: 1.5;
  padding: 0.8rem 3.2rem;
  border-radius: 999rem;
  overflow: hidden;
  position: relative;
  display: inline-block;
  z-index: 1;
  transition: color 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .contact[data-v-7653fc8a]:before {
  content: "";
  background: #f16bae;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .contact[data-v-7653fc8a]:hover {
  color: #f16bae;
}
header .contact[data-v-7653fc8a]:hover:before {
  left: auto;
  right: 0;
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./headers.vue","webpack://./src/js/components/headers.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;AClBF;ADmBC;AARD;IASE,eAAA;AChBA;AACF;ADiBC;AAXD;IAYE,eAAA;IACE,YAAA;ACdF;AACF;ADeC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACZF;AACF;ADuGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACpGD;AC9DA;EACE,+CAAA;EACA,eAAA;EACA,SAAA;EACA,6BAAA;EACA,eAAA;EACA,qBAAA;EACA,gBFEM;EEDN,aAAA;EACA,gCAAA;EACA,qDAAA;EACA,aAAA;ADgEF;AC/DE;EACE,kBAAA;ADiEJ;AC/DE;EACE,aAAA;EACA,wBAAA;EACA,kBAAA;ADiEJ;AChEI;EACE,WAAA;ADkEN;AC/DE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;ADiEJ;AChEI;EFmBH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EEpBI,mBAAA;EACA,uBAAA;ADqEN;ACpEM;EACE,cAAA;EACA,oBAAA;ADsER;ACrEQ;EACE,cAAA;ADuEV;ACrEQ;EACE,yBAAA;EACA,kBAAA;EACA,cFtCD;EEuCC,aFvCD;EEwCC,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,UAAA;ADuEV;ACtEU;EACE,cF5CL;EE6CK,aF7CL;ACqHP;ACtEU;EACE,cAAA;EACA,eAAA;EACA,qBAAA;EACA,qBAAA;ADwEZ;AClEE;EACE,sBAAA;EFDF,WArDM;EAsDN,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBALiD;EAMjD,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,UAAA;EACA,sDAAA;ACsEF;ADrEE;EACE,WAAA;EACA,mBAtEG;EAuEH,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,sDAAA;ACuEJ;ADrEE;EACE,cAhFG;ACuJP;ADtEI;EACE,UAAA;EACA,QAAA;EACA,QAAA;ACwEN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
