import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as THREE from 'three'

gsap.registerPlugin(ScrollTrigger)

export default function index(){
  const mainPic = document.querySelector('section.main')
  if(mainPic){
    mainPic.classList.add('active')
  }

  const container = document.getElementById('particles')

  const scene = new THREE.Scene()
  const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000)
  const renderer = new THREE.WebGLRenderer({ alpha: true }) 
  renderer.setSize(container.clientWidth, container.clientHeight)
  container.appendChild(renderer.domElement)
  
  const geometry = new THREE.CircleGeometry(.5, 64)
  const confettiCount = 128
  const confettis = []
  
  function getRandomColor() {
    const colors = [0xffbde4, 0xc7f4fe, 0xffffff]
    return colors[Math.floor(Math.random() * colors.length)]
  }

  for (let i = 0; i < confettiCount; i++) {
    const material = new THREE.MeshBasicMaterial({ color: getRandomColor(), side: THREE.DoubleSide })
    const confetti = new THREE.Mesh(geometry, material)
  
    const xPosition = (Math.random() - 0.5) * 100  // X軸範囲 (-50 ~ 50)
    const yPosition = Math.random() * -50 - 20      // Y軸範囲を下に
    const zPosition = (Math.random() - 0.5) * 20   // Z軸範囲を狭める (-10 ~ 10)
  
    confetti.position.set(xPosition, yPosition, zPosition)
    confetti.scale.set(0.5, 0.5, 0.5) // 初期スケール
    
    confetti.userData = {
      speed: Math.random() * 0.1 + 0.05,
      scaleSpeed: Math.random() * 0.01 + 0.005,
      scaleDirection: 1
    }
  
    scene.add(confetti)
    confettis.push(confetti)
  }
  
  camera.position.z = 20
  
  function animate() {
    requestAnimationFrame(animate)
  
    confettis.forEach(confetti => {
      confetti.position.y += confetti.userData.speed
  
      confetti.scale.x += confetti.userData.scaleSpeed * confetti.userData.scaleDirection
      confetti.scale.y += confetti.userData.scaleSpeed * confetti.userData.scaleDirection
  
      if (confetti.scale.x > 1 || confetti.scale.x < 0.5) {
        confetti.userData.scaleDirection *= -.5
      }
  
      if (confetti.position.y > 20) {
        confetti.position.y = Math.random() * -50 - 20
        confetti.position.x = (Math.random() - 0.5) * 100
        confetti.position.z = (Math.random() - 0.5) * 20  // Z軸範囲を狭める
      }
    })
  
    renderer.render(scene, camera)
  }
  
  animate()
  
  window.addEventListener('resize', () => {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(container.clientWidth, container.clientHeight)
  })
  

}
