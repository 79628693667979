// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Changa:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-18c2bfa6]:root {
  font-size: calc(14.5px + 1.5 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-18c2bfa6]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-18c2bfa6]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-18c2bfa6]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
.pic[data-v-18c2bfa6] {
  position: relative;
  padding: 25% 0 0;
  min-height: 20rem;
}
.pic[data-v-18c2bfa6]:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #625555;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  z-index: 2;
}
.pic img[data-v-18c2bfa6] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pic div[data-v-18c2bfa6] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  text-align: center;
  line-height: 1.5;
  font-weight: 700;
}
.pic div p[data-v-18c2bfa6] {
  font-size: 3.6rem;
}
.pic div h1[data-v-18c2bfa6] {
  font-size: 1.12rem;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./hero.vue","webpack://./src/js/components/hero.vue"],"names":[],"mappings":"AAGA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,sDAAA;EACA,OAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;AClBF;ADmBC;AARD;IASE,eAAA;AChBA;AACF;ADiBC;AAXD;IAYE,eAAA;IACE,YAAA;ACdF;AACF;ADeC;AAfD;IAgBE,iBAAA;IACE,YAAA;ACZF;AACF;ADuGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACpGD;ADsGA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACpGD;AC9DA;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;ADgEF;AC/DE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,mBFLK;EEML,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,UAAA;ADiEJ;AC/DE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;ADiEJ;AC/DE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,WFpBI;EEqBJ,kBAAA;EACA,gBAAA;EACA,gBAAA;ADiEJ;AChEI;EACE,iBAAA;ADkEN;AChEI;EACE,kBAAA;ADkEN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
