import * as Rellax from 'rellax'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'

gsap.registerPlugin(ScrollTrigger);

export default function common() {
  
  if(document.getElementsByClassName('rellax').length > 0){
    const rellax = new Rellax('.rellax',{
      center:true
    });
  }
  if(document.getElementsByClassName('rellax-no').length > 0){
    const rellax_no = new Rellax('.rellax-no',{
      center:false
    })
  }

  const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
    header: '#site-header',
    offset: 0,
    speed: 1000,
    easing: 'easeInOutQuart',
    speedAsDuration: true,
    durationMin: 1000
  })

  gsap.utils.toArray('.fixside').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: '#app',
      start: "top top-=100%",
      end: "bottom top",
      toggleClass: { targets: el, className: "active" },
      once: false,
      markers: false,
    });
  })

  gsap.utils.toArray('.toAnimation').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top center+=15%",
      end: "bottom center",
      toggleClass: { targets: el, className: "active" },
      once: true,
      //markers: true,
    });
  })

  gsap.utils.toArray('.toAnimationAll').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      start: "top center+=15%",
      end: "bottom center",
      toggleClass: { targets: el, className: "active" },
      once: true,
      //markers: true,
    });
  })

  setViewport()
  window.addEventListener('resize', setViewport)
}

function setViewport(){
  const vw = window.innerWidth;
  const vh = window.innerHeight * 0.01
  const head = document.getElementById('site-header')
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vw', `${vw}px`)
  if(vw < 769){ document.documentElement.style.setProperty('--size', '10px') }
  else if(vw < 1025){ document.documentElement.style.setProperty('--size', '16px') }
  else{ document.documentElement.style.setProperty('--size', '32px') }
  if(head){
    const headSize = head.getBoundingClientRect().height
    document.documentElement.style.setProperty('--head', `${headSize}px`)
  }
}