<template>
  <header id="site-header">
    <figure class="logo">
      <a href="./">
        <img src="/images/common/logo.webp" alt="リルリボン" />
      </a>
    </figure>
    <nav>
      <ul>
        <li><a href="#concept">Concept</a></li>
        <li><a href="#worldview">World view</a></li>
        <li><a href="#requirements">Requirements</a></li>
        <li><a href="#process">Process</a></li>
        <li><a href="#method">Method</a></li>
      </ul>
    </nav>
    <a href="#entry" class="contact">Entry</a>
  </header>
</template>

<style lang="scss" scoped>
header{
  width: calc(100% - calc(#{side()} * 2));
  position: fixed;
  top: 1rem;
  left: side();
  padding: .8rem;
  border-radius: 999rem;
  background: $white;
  display: grid;
  grid-template-columns: auto auto;
  box-shadow: 0 .4rem 2.4rem rgba($base,.24);
  z-index: 1000;
  > *{
    align-self: center;
  }
  .logo{
    width: 9.6rem;
    justify-self: flex-start;
    margin: 0 0 0 1rem;
    img{
      width: 100%;
    }
  }
  nav{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ul{
      @include flex();
      align-items: center;
      justify-content: center;
      li{
        line-height: 1;
        margin: 0 0 0 2.4rem;
        &:first-child{
          margin-left: 0;
        }
        a{
          text-transform: uppercase;
          font-size: 1.04rem;
          color: $normal;
          fill: $normal;
          display: inline-flex;
          align-items: center;
          position: relative;
          padding: .4rem;
          z-index: 1;
          &:hover{
            color: $dark;
            fill: $dark;
          }
          svg{
            width: 7.2vmin;
            height: 7.2vmin;
            display: inline-block;
            margin: 0 0 0 .8vmin;
          }
        }
      }
    }
  }
  .contact{
    justify-self: flex-end;
    @include btn($white, $dark);
  }
}
</style>

<script>
export default {
  data: function(){
    return {
      trg: false
    }
  }
}
</script>
