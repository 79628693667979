<template>
  <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="0"
    :loop="false"
    :navigation="false"
    :pagination="{ el: '.firstview__pager', clickable: true }"
    :scrollbar="{ draggable: true }"
    :effect="'fade'"
    :speed = "2000"
    :fade-effect="{ crossFade: true }"
    :autoplay="{ delay:1000, disableOnInteraction: false }"
    :allow-touch-move="false"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="(item, index) in list" :key="index">
      <div class="firstview__pic">
        <img :src="item.pic" alt="photo"> 
      </div>
      <div class="firstview__data">
        <div class="firstview__data__inner">
          <p class="eng" v-html="item.eng"></p>
          <p class="jp" v-html="item.jp" :class="item.cls"></p>
          <div class="btnarea" v-if="list.length - 1 == index">
            <a href="">ゼンケイについて見る<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M665.08-450H180v-60h485.08L437.23-737.85 480-780l300 300-300 300-42.77-42.15L665.08-450Z"/></svg></a>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
    }
    const onSlideChange = (swiper) => {
      if (swiper.isEnd) {
        swiper.autoplay.stop();
      }
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade],
    }
  },
}
</script>
