<template>
	<footer id="site-footer" class="in-module" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/WPFooter">
    <small class="copy">Copyright &copy; {{ currentYear }} Cospanic Entertainment. All rights reserved.</small>
	</footer>
</template>

<style lang="scss" scoped>
footer{
  background: $dark;
  padding: 2rem 0;
  color: $white;
  small{
    display: block;
    text-align: center;
    font-size: .72rem;
  }
}
</style>

<script>
export default {
	data() {
		return {
			currentYear: new Date().getFullYear()
		}
	}
}
</script>