<template>
  <swiper
    :modules="modules"
    :slides-per-view="1.5"
    :space-between="10"
    :speed="4000"
    :loop="true"
    :autoplay="{ delay: 0, disableOnInteraction: false, reverseDirection: reverse }"
    :breakpoints="breakpoints"
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="(item, index) in list" :key="index">
      <img :src="item.pic" alt="photo">
    </swiper-slide>
  </swiper>
</template>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

<script>
import { ref, toRefs } from 'vue'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const { reverse } = toRefs(props);
    const breakpoints = {
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 0,
      },
      769: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
    };

    const onSwiper = (swiper) => {
    }
    const onSlideChange = () => {
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      reverse,
      breakpoints,
    }
  },
}
</script>
